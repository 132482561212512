  .sticky-table .sticky-table-cell {
    display: table-cell;
    box-sizing: border-box;
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    background: white;
    padding: 10px;
  }
  .sticky-table .sticky-table-row {
    display: table-row;
  }
  .sticky-table .sticky-table-table {
    display: table;
    box-sizing: border-box;
  }
  .sticky-table {
    position: relative;
    overflow: auto;
    height: 100%;
    box-sizing: border-box;
  }