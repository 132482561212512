.card-max-height {
    max-height: 15rem;
}

.card-header-margin {
    margin-top: 0 !important;
    margin-bottom: 10px !important;
}

.card-footer-margin {
    padding-top: 0 !important;
    margin-top: 10px !important;
}

.card-footer-buttons-padding {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
    padding-top: 0 !important;
    margin-top: 10px !important;
}

.table-column-header {
    font-size: 0.8rem;
    color: #f96332;
}

.table-body-myclass td{
    outline: none;
}

.table-highlight-myclass {
    color: white;
    background-color: #4cabf7;
}

.datepicker-wrap-myclass {
    display: block !important;
}

.datepicker-myclass {
    width: 100%;
    height: 35px;
    border-radius: 30px;
    border-width: 1px;
    border-style: solid;
    border-color: #e9ecef;
    padding-left: 20px;
}

.datepicker-myclass-compulsory {
    width: 100%;
    height: 35px;
    border-radius: 30px;
    border-width: 2px;
    border-style: solid;
    border-color: red;
    padding-left: 20px;
}

.datepicker-myclass-disabled {
    width: 100%;
    height: 35px;
    border-radius: 30px;
    border-width: 0;
    padding-left: 20px;
    background-color: #e9ecef;
}

.react-datepicker__input-container {
    width: 100%;
    position: relative;
    display: inline-block;
}  

.table-freeze-topheader {
    position: -webkit-sticky; /* for Safari */
    position: sticky;
    top: 0;
    background: #FFF;
    z-index: 2;
} 

.table-freeze-leftheader {
    position: -webkit-sticky; /* for Safari */
    position: sticky;
    left: 0;
    background: #FFF;
    z-index: 1;
    border-bottom: 1px solid #ccc;
} 

.table-header-cell-left-style {
    padding: 10px 10px 10px 0;
    text-align: left;
}

.table-header-cell-style {
    width: max-content;
    text-align: left;
    padding: 10px;
}

.table-header-cell-left-style span, .table-header-cell-style span {
    line-height: 30px;
}
